import * as React from 'react';
import Seo from "../components/seo";
import Layout from '../components/layout';
import { StaticImage } from "gatsby-plugin-image";

export class ProjectsPage extends React.Component {
  render() {
    return (
      <Layout>
        <Seo title="Projects" />
        <h2 className="text-4xl font-bold mb-6 border-b pb-4 text-gray-900">Projects</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          {/* Cinetimes */}
          <div className="bg-white shadow-md rounded-lg overflow-hidden border-2 border-gray-200 transition-all duration-300 hover:shadow-lg hover:border-gray-400">
            <div className="flex flex-col">
              <div className="w-full">
                <StaticImage
                  src="../images/cinetimes.jpg"
                  alt="Cinetimes Preview"
                  className="w-full"
                  transformOptions={{
                    fit: "contain",
                    cropFocus: "center"
                  }}
                  placeholder="blurred"
                />
              </div>
              <div className="p-6">
                <a href="https://cinetimes.org" className="text-gray-900 hover:text-black" target="_blank" rel="noopener noreferrer">
                  <h3 className="text-2xl font-bold mb-2">Cinetimes</h3>
                </a>
                <p className="text-gray-700 mb-4">
                  Free streaming platform aggregating movies, cartoons, and documentaries from official sources. 
                  Features a Netflix-like interface with machine learning recommendations and sends a weekly newsletter 
                  to subscribers. Available in French, Spanish and English, monetized through advertisements.
                </p>
                <a 
                  href="https://cinetimes.org" 
                  className="inline-block bg-gray-800 text-white py-2 px-4 rounded hover:bg-black transition-colors duration-300"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Site
                </a>
              </div>
            </div>
          </div>

          {/* Fotoia */}
          <div className="bg-white shadow-md rounded-lg overflow-hidden border-2 border-gray-200 transition-all duration-300 hover:shadow-lg hover:border-gray-400">
            <div className="flex flex-col">
              <div className="w-full">
                <StaticImage
                  src="../images/fotoia.jpg"
                  alt="Fotoia Preview"
                  className="w-full"
                  transformOptions={{
                    fit: "contain",
                    cropFocus: "center"
                  }}
                  placeholder="blurred"
                />
              </div>
              <div className="p-6">
                <a href="https://fotoia.es" className="text-gray-900 hover:text-black" target="_blank" rel="noopener noreferrer">
                  <h3 className="text-2xl font-bold mb-2">Fotoia</h3>
                </a>
                <p className="text-gray-700 mb-4">
                  AI photography platform that transforms selfies into professional photos and artistic portraits. 
                  Features include multiple style options, custom model training, and an AI studio for image generation. 
                  Offers tiered subscription plans with different levels of generation and training credits.
                </p>
                <a 
                  href="https://fotoia.es" 
                  className="inline-block bg-gray-800 text-white py-2 px-4 rounded hover:bg-black transition-colors duration-300"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Site
                </a>
              </div>
            </div>
          </div>

          {/* WebsiteHunt */}
          <div className="bg-white shadow-md rounded-lg overflow-hidden border-2 border-gray-200 transition-all duration-300 hover:shadow-lg hover:border-gray-400">
            <div className="flex flex-col">
              <div className="w-full">
                <StaticImage
                  src="../images/websitehunt.jpg"
                  alt="WebsiteHunt Preview"
                  className="w-full"
                  transformOptions={{
                    fit: "contain",
                    cropFocus: "center"
                  }}
                  placeholder="blurred"
                />
              </div>
              <div className="p-6">
                <a href="https://www.websitehunt.co" className="text-gray-900 hover:text-black" target="_blank" rel="noopener noreferrer">
                  <h3 className="text-2xl font-bold mb-2">WebsiteHunt</h3>
                </a>
                <p className="text-gray-700 mb-4">
                  A platform showcasing little-known yet useful websites, complemented by a weekly newsletter featuring 
                  the most upvoted sites. With over 2,350 email subscribers, it serves as a discovery platform for 
                  interesting web projects.
                </p>
                <a 
                  href="https://www.websitehunt.co" 
                  className="inline-block bg-gray-800 text-white py-2 px-4 rounded hover:bg-black transition-colors duration-300"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Site
                </a>
              </div>
            </div>
          </div>

          {/* Proximovie */}
          <div className="bg-white shadow-md rounded-lg overflow-hidden border-2 border-gray-200 transition-all duration-300 hover:shadow-lg hover:border-gray-400">
            <div className="flex flex-col">
              <div className="w-full">
                <StaticImage
                  src="../images/proximovie.jpg"
                  alt="Proximovie Preview"
                  className="w-full"
                  transformOptions={{
                    fit: "contain",
                    cropFocus: "center"
                  }}
                  placeholder="blurred"
                />
              </div>
              <div className="p-6">
                <a href="https://proximovie.com" className="text-gray-900 hover:text-black" target="_blank" rel="noopener noreferrer">
                  <h3 className="text-2xl font-bold mb-2">Proximovie</h3>
                </a>
                <p className="text-gray-700 mb-4">
                  Movie recommendations website leveraging user votes and AI technology. Features various similarity 
                  algorithms using GPT embeddings for plot analysis, visual embeddings for posters, and metadata 
                  comparison. Includes thousands of keyword pages where users can vote on movies.
                </p>
                <a 
                  href="https://proximovie.com" 
                  className="inline-block bg-gray-800 text-white py-2 px-4 rounded hover:bg-black transition-colors duration-300"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Site
                </a>
              </div>
            </div>
          </div>

          {/* Alternativas */}
          <div className="bg-white shadow-md rounded-lg overflow-hidden border-2 border-gray-200 transition-all duration-300 hover:shadow-lg hover:border-gray-400">
            <div className="flex flex-col">
              <div className="w-full">
                <StaticImage
                  src="../images/alternativas.jpg"
                  alt="Alternativas Preview"
                  className="w-full"
                  transformOptions={{
                    fit: "contain",
                    cropFocus: "center"
                  }}
                  placeholder="blurred"
                />
              </div>
              <div className="p-6">
                <a href="https://alternativas.io" className="text-gray-900 hover:text-black" target="_blank" rel="noopener noreferrer">
                  <h3 className="text-2xl font-bold mb-2">Alternativas</h3>
                </a>
                <p className="text-gray-700 mb-4">
                  Spanish crowdsourced software alternatives website where users can login, add software, search 
                  alternatives, and participate in community discussions through comments and upvotes.
                </p>
                <a 
                  href="https://alternativas.io" 
                  className="inline-block bg-gray-800 text-white py-2 px-4 rounded hover:bg-black transition-colors duration-300"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Site
                </a>
              </div>
            </div>
          </div>

          {/* Empleovino */}
          <div className="bg-white shadow-md rounded-lg overflow-hidden border-2 border-gray-200 transition-all duration-300 hover:shadow-lg hover:border-gray-400">
            <div className="flex flex-col">
              <div className="w-full">
                <StaticImage
                  src="../images/empleovino.jpg"
                  alt="Empleovino Preview"
                  className="w-full"
                  transformOptions={{
                    fit: "contain",
                    cropFocus: "center"
                  }}
                  placeholder="blurred"
                />
              </div>
              <div className="p-6">
                <a href="https://empleovino.es" className="text-gray-900 hover:text-black" target="_blank" rel="noopener noreferrer">
                  <h3 className="text-2xl font-bold mb-2">Empleovino</h3>
                </a>
                <p className="text-gray-700 mb-4">
                  Specialized job board for the Spanish wine industry. Companies can post job listings while job seekers 
                  browse positions in wineries, distributors, and related businesses. Features include category pages, 
                  location-based searching, and automated social media posting.
                </p>
                <a 
                  href="https://empleovino.es" 
                  className="inline-block bg-gray-800 text-white py-2 px-4 rounded hover:bg-black transition-colors duration-300"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Site
                </a>
              </div>
            </div>
          </div>

          {/* Attention au Portefeuille */}
          <div className="bg-white shadow-md rounded-lg overflow-hidden border-2 border-gray-200 transition-all duration-300 hover:shadow-lg hover:border-gray-400">
            <div className="flex flex-col">
              <div className="w-full">
                <StaticImage
                  src="../images/attentionauportefeuille.jpg"
                  alt="Attention au Portefeuille Preview"
                  className="w-full"
                  transformOptions={{
                    fit: "contain",
                    cropFocus: "center"
                  }}
                  placeholder="blurred"
                />
              </div>
              <div className="p-6">
                <a href="https://www.attentionauportefeuille.com" className="text-gray-900 hover:text-black" target="_blank" rel="noopener noreferrer">
                  <h3 className="text-2xl font-bold mb-2">Attention au Portefeuille</h3>
                </a>
                <p className="text-gray-700 mb-4">
                  A French affiliate website focused on curated gift guides, helping users discover perfect presents 
                  while maintaining budget consciousness.
                </p>
                <a 
                  href="https://www.attentionauportefeuille.com" 
                  className="inline-block bg-gray-800 text-white py-2 px-4 rounded hover:bg-black transition-colors duration-300"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Site
                </a>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ProjectsPage;